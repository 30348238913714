<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>

        {{ i18n("Custom.ParameterSettings") }}
      </span>
    </div>

    <div class="page-tabs">
      <template v-for="(item, index) in rows">
        <a
          :key="item.index"
          :class="{ active: currentTab === index }"
          @click.prevent="currentTab = index"
          href="#"
        >
          {{ item.title }}
        </a>
      </template>
    </div>

    <div class="page-tabs-content">
      <ValidationObserver v-slot="{ handleSubmit }">
        <div v-for="(item, index) in rows" :key="item.index">
          <table class="table" v-if="currentTab === index">
            <tbody>
              <tr v-for="data in item.content" :key="data.index">
                <td>
                  {{ data.Memo }}
                  <!-- Gmail信件收集履歷 -->
                  <span
                    v-if="data.Memo === 'Gmail信件收集履歷'"
                    class="explanation"
                    data-toggle="modal"
                    data-target="#gmailExplanationModal"
                  >
                    ?
                  </span>
                  <!-- O365信件收集履歷 -->
                  <span
                    v-if="data.Memo === 'O365信件收集履歷'"
                    class="explanation"
                    data-toggle="modal"
                    data-target="#officeExplanationModal"
                  >
                    ?
                  </span>
                </td>
                <!-- 字串格式 -->
                <td v-if="data.DataType === 'String'">
                  <input
                    type="text"
                    class="form-control"
                    v-model.trim="data.Value"
                  />
                </td>

                <!-- 多行文字格式 -->
                <td v-if="data.DataType === 'TextArea'">
                  <textarea
                    class="form-control"
                    rows="4"
                    v-if="
                      data.ConfigClass === '人員資料維護' ||
                      data.ConfigClass === '測驗發布設定'
                    "
                    v-model="data.Value"
                  />
                  <input
                    v-else
                    type="text"
                    class="form-control"
                    v-model.trim="data.Value"
                  />
                </td>
                <!-- 密碼格式 -->
                <td v-if="data.DataType === 'Password'">
                  <input type="password" class="form-control" v-model.trim="data.Value" />
                </td>
                <!-- 數字格式 -->
                <td v-if="data.DataType === 'Int'">
                  <el-input-number
                    class="w-100"
                    :min="1"
                    :max="99999"
                    @blur="!data.Value ? (data.Value = 1) : null"
                    v-model="data.Value"
                  />
                </td>
                <!-- 開關格式 -->
                <td v-if="data.DataType === 'Bool'">
                  <el-radio v-model="data.Value" label="true">
                    {{ i18n("Basic.On") }}
                  </el-radio>
                  <el-radio v-model="data.Value" label="false">
                    {{ i18n("Basic.Off") }}
                  </el-radio>
                </td>
                <!-- 上傳格式 -->
                <td v-if="data.DataType === 'Img'">
                  <el-upload
                    class="upload-demo"
                    action
                    accept="image/jpeg,image/png"
                    :auto-upload="false"
                    :show-file-list="false"
                    list-type="picture"
                    :on-change="uploadFile"
                    v-model="data.Value"
                  >
                    <!-- 首頁背景圖片 -->
                    <el-button
                      v-if="data.Guid === '606190f5-7c43-ec11-876a-00155d014913'"
                      size="small"
                      type="primary"
                      @click="clickUpload('homepage')"
                    >
                      上傳圖片
                    </el-button>
                    <!-- 公司LOGO(適用淺色背景) -->
                    <el-button
                      v-if="data.Guid === 'b27958b4-744b-4655-950e-1d819bf24a2a'"
                      size="small"
                      type="primary"
                      @click="clickUpload('dark')"
                    >
                      上傳圖片
                    </el-button>
                    <!-- 公司LOGO(適用深色背景) -->
                    <el-button
                      v-if="data.Guid === 'b27958b4-744b-4655-950e-1d819bf24a2b'"
                      size="small"
                      type="primary"
                      @click="clickUpload('light')"
                    >
                      上傳圖片
                    </el-button>
                    <div slot="tip" class="el-upload__tip mark">
                      僅能上傳 jpg/png 檔案，且大小不超過10MB
                    </div>
                  </el-upload>
                  <img style="width: 120px; height: 120px" :src="data.ImgBase64" />
                </td>

                 <!-- 編輯器格式 -->
                 <td v-if="data.DataType === 'TextEditor'" :class="{'has-bg':(data.Memo==='測驗公告'||data.Memo==='基本資料頁面說明')}">

                 <vue-editor v-model="data.Value" :editorToolbar="customToolbar"></vue-editor>

                </td>
              </tr>
              <tr>
                <td colspan="2" class="text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="handleSubmit(editConfig(item))"
                  >
                    {{ i18n("Basic.Save") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  data() {
    return {
      screen: null, // loading遮罩
      currentTab: 0,

      // data
      rows: [],

      // 首頁下方背景圖
      homepageBackground: "",
      // 深色LoGo
      logoDark: "",
      // 淺色LoGo
      logoLight: "",

      // 上傳類型
      uploadType: "",

      customToolbar: [

        [{ 'size': ['small', false, 'large', 'huge'] }],
        [ 'bold', 'italic', 'underline'],
        [{ 'align': '' }, { 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'color': [] }, { 'background': [] }],
      ]
    };
  },
  components: {
    VueEditor,
  },
  computed: {},
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    // 取得 系統設定參數
    getConfig() {
      this.screen = this.openLoading();
      this.rows = [];
      const url = `${window.BaseUrl.api}/system/config`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            let arr = [];
            arr = Object.keys(response.data.Data);
            for (let i = 1; i < arr.length; ++i) {
              this.rows.push({
                title: arr[i],
                content: response.data.Data[arr[i]],
              });
            }
          }

          console.log("this.rows", this.rows);
          this.screen.close();
        })
        .catch(() => {
          this.screen.close();
        });
    },

    // 修改 系統設定參數
    editConfig(rows) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/system/config`;
      let params = {
        Configs: rows.content,
      };

      this.$http
        .put(url, params)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.$notify({
              message: "儲存成功",
              type: "success",
            });
            this.getConfig();
          }

          this.screen.close();
        })
        .catch(() => {
          this.screen.close();
        });
    },
  },
  created() {
    this.getConfig();
  },
};
</script>
<style lang="scss">
.page-tabs {
  margin-bottom: 20px;
  a {
    display: inline-block;
    background-color: #fff;
    padding: 10px 15px;
    color: #383838;
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }

    &.active {
      background-color: #5380f7;
      color: #fff;
      position: relative;

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #5380f7 transparent transparent transparent;
        position: absolute;
        left: 50%;
        bottom: -10px;
        transform: translateX(-50%);
      }
    }
  }
}

.page-tabs-content {
  table {
    background-color: #fff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    tr:first-child {
      td,
      th {
        border-top: none;
      }
      th {
        background-color: #faf9f8;
        font-weight: normal;
        color: #646464;
      }
    }
    .explanation {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      display: inline-block;
      background-color: #df2e2e;
      color: #fff;
      z-index: 3;
      font-weight: bold;
      margin-left: 10px;
      font-size: 1.2em;
      cursor: pointer;
      vertical-align: middle;
      padding: 0;
    }
  }
}
.has-bg{
  background-image: url("~@/assets/Images/37130.jpg");
  background-size: cover;
  .ql-toolbar {
    background-color: #fff;
}
}
</style>
